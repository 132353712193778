<template>
    <main class="flex-grow-1 align-content-center">
        <div class="container">
            <div style=" width: 0;" class="d-none d-lg-block mt-5">
                <h1 class="">Privacy&nbsp;Policy and&nbsp;GDPR</h1>
            </div>
            <h1 class="d-block d-lg-none mt-4">Privacy&nbsp;Policy and&nbsp;GDPR</h1>


            <div class="mt-5 mb-5">
                <div>
                    <h6>What information do we collect</h6>
                    <ul>
                        <li>Currently we collect (and store), your name, email address, physical address.</li>
                        <li>We also collect your buyers email address, physical address and full name, this is required to generate the main PDFs that our system uses</li>
                        <li>Any payment methods are securely stored with Stripe</li>
                    </ul>
                </div>
                <div class="mt-2">
                    <h6>How do we get our data</h6>
                    <div>All our data is obtained via the Etsy API with your explicit permission when signing in via Etsy. This also gives us permission to your buyers details for order fufillment.</div>
                </div>
                <div class="mt-2">
                    <h6>How long do we store it</h6>
                    <div>Data about you is kept indefinately unless requested for deletion by you, we use this to keep metrics of how many customers and their country for our marketing.</div>
                    <div>Data about your buyers is deleted once a PDF of the order has been generated and sent via email.</div>
                </div>
                <div class="mt-2">
                    <h6>Where do we store it</h6>
                    <div>All our data is stored securely within Azure under UK datacenters.</div>
                </div>
                <div class="mt-2">
                <h6>Where do we share it</h6>
                    <div>We do not share any of your data with any third parties.</div>
                </div>
                <div class="mt-2">
                    <h6>Data controllers</h6>
                    <div>Etsy is responsible for your data, by signing in via the Etsy API, they give us access to your data turning us into a temporary data controller.</div>
                    </div>
                <div class="mt-2">
                    <h6>GDPR</h6>
                    <div>Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are:</div>
                    <ul>
                        <li>Your consent. You are able to remove your consent at any time. You can do this by contacting hello@.com</li>
                    </ul>
                </div>
                <div class="mt-2">
                    <h6>Data stored on your devices</h6>
                    <div>This website does not implement any kind of analytic tracking as of 18th Aug 2021, this will be updated should this be implemented.</div>
                    <div>As such we do not store any cookies on your device, however, we do utilize a modern browser storage medium called LocalStorage. We use this to store your login session and cache your settings. This is not deleted at any point by us and remains on your device unless you clear your website data.</div>
                </div>
            </div>
            <div class="my-5">
                This page was last updated 18th Aug, 2021
            </div> 
        </div>
    </main>
</template>

<script>
export default {

}
</script>

<style>

</style>